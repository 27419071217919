<template>
  <div class="data-overview">
    <div class="title">{{title}}</div>
    <div class="data-content">
      <div class="data-item" v-for="(value, key) in datas" :key="key">
        <div class="item-name">{{ $t(key) }}</div>
        <div class="item-value">{{ value | add_comma_toThousands }}</div>
      </div>
      <!-- <div class="data-item">
        <div class="item-name">{{ $t('block.dataNum') }}</div>
        <div class="item-value"></div>
      </div>
      <div class="data-item">
        <div class="item-name">{{ $t('block.tradeNum') }}</div>
        <div class="item-value"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    datas: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
@import './title.less';
.data-overview{
  padding: 24px;
  margin-bottom: 16px;
  background: #FFFFFF;

  .data-content{
    display: flex;
    padding: 16px 0 24px;
    .data-item{
      flex: 1;
    }
    .item-name{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .item-value{
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 32px;
    }
  }
}

@media screen and (max-width:1024px) {
 .data-overview{
  padding: 16px;
  margin-bottom: 8px;
  .data-content{
    padding: 8px 0;
     .item-name{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      margin-bottom:0;
    }
    .item-value{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
  }

 }
}
</style>
