<template>
  <div class="home-data">
    <overview :title="$t('block.yesterday')" :datas="yesterdayData"></overview>
    <overview :title="$t('block.total')" :datas="totalData"></overview>
    <!-- <div class="pc-list"> -->
    <tableList
      :dataList="blockList"
      :mDataList="blockList.slice(0, 5)"
      :title="$t('table.newBlockData')"
      :columns="blockColumns"
      :mColumns="mBlockColumns"
      :showMore="true"
      @more="toMoreBlock('/moreBlockData')"
    >
      <template #prefix>
        <div class="title-prefix">
          <el-divider direction="vertical"></el-divider>
          <el-radio-group
            v-model="activeBlockType"
            size="small"
            @change="getBlockData"
          >
            <el-radio-button
              :label="key"
              v-for="(value, key) in blockType"
              :key="key"
              >{{ value }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </template>
      <template #hash="{ scoped }">
        <div
          class="blue text-overflow2"
          @click="toHashDetail(scoped.row.blockHash)"
        >
          {{ scoped.row.blockHash }}
        </div>
      </template>
    </tableList>
    <!-- es停用，广告列表暂时不展示 -->
    <!-- <tableList
      :dataList="adList"
      :mDataList="adList.slice(0, 5)"
      :title="$t('table.newCreateData')"
      :columns="newDataColumns"
      :mColumns="mNewDataColumns"
      :showMore="true"
      @more="toMoreBlock('/moreCreateData')"
    >
      <template #adurl="{ scoped }">
        <div class="text-overflow">
          <a
            :href="scoped.row.adUrl"
            v-if="isCanJump(scoped.row.adUrl)"
            class="blue"
            target="_blank"
            >{{ scoped.row.adUrl }}</a
          >
          <span v-else>{{ scoped.row.adUrl }}</span>
        </div>
      </template>
      <template #adUrl="{ scoped }">
        <div class="text-overflow2">
          {{ scoped.row.adUrl }}
        </div>
      </template>
      <template #isJump="{ scoped }">
        <div>
          {{ isCanJump(scoped.row.adUrl) ? '是' : '否' }}
        </div>
      </template>
    </tableList> -->
    <!-- </div> -->
    <!-- <div class="mobile-list">
      <mobileTableList
        :dataList="blockList.slice(0,5)"
        :title="$t('table.newBlockData')"
        :columns="mBlockColumns"
        :showMore="true"
        @more="toMoreBlock('/moreBlockData')">
        <template #prefix>
          <div class="title-prefix">
            <el-radio-group
              v-model="activeBlockType"
              size="small"
              @change="getBlockData"
            >
              <el-radio-button
                :label="key"
                v-for="(value, key) in blockType"
                :key="key"
                >{{ value }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </template>
         <template #hash="{scoped}">
          <div class="blue text-overflow2" @click="toHashDetail(scoped.row.blockHash)">
            {{ scoped.row.blockHash }}
          </div>
        </template></mobileTableList>
      <mobileTableList
       :dataList="adList.slice(0,5)"
        :title="$t('table.newCreateData')"
        :columns="mNewDataColumns"
        :showMore="true"
        @more="toMoreBlock('/moreCreateData')">
         <template #adUrl="{scoped}">
          <div class="text-overflow2">
            {{ scoped.row.adUrl }}
          </div>
        </template></mobileTableList>
    </div> -->
  </div>
</template>

<script>
import overview from '@/components/data/overview'
import tableList from '@/components/data/tableList.vue'
// import mobileTableList from '@/components/data/mobileTableList'
import api from '@/api/services/browser'
import tableConfig from '@/components/data/tableConfig'
import { isCanJump } from '@/utils'

export default {
  components: {
    overview,
    tableList
    // mobileTableList
  },
  data() {
    return {
      adList: [],
      blockList: [],
      blockColumns: tableConfig.blockColumns,
      newDataColumns: tableConfig.newDataColumns,
      mBlockColumns: tableConfig.mBlockColumns,
      mNewDataColumns: tableConfig.mNewDataColumns,
      yesterdayData: {
        'block.blockNum': 0,
        'block.tradeNum': 0,
        'block.dataNum': 0
      },
      totalData: {
        'block.blockNum': 0,
        'block.tradeNum': 0,
        'block.dataNum': 0
      },
      blockType: {
        adf: 'ADFun区块',
        nft: 'NFT区块'
      },
      activeBlockType: 'adf'
    }
  },
  created() {
    const query = this.$route.query
    if (query.type) {
      this.activeBlockType = query.type
    }
    this.getData()
  },
  methods: {
    getData() {
      this.getOverView()
      // this.getAdData()
      this.getBlockData()
    },
    async getOverView() {
      const data = await api.getStaticData()

      this.yesterdayData['block.blockNum'] = data.yesterdayBlockCount
      this.yesterdayData['block.tradeNum'] = data.yesterdayTransCount
      this.yesterdayData['block.dataNum'] = data.yesterdayDataCount
      this.totalData['block.blockNum'] = data.totalBlockCount
      this.totalData['block.tradeNum'] = data.totalTransCount
      this.totalData['block.dataNum'] = data.totalDataCount
    },
    async getAdData() {
      const data = await api.getAdData({
        page: 1,
        size: 10
      })
      this.adList = data.adList || []
    },
    async getBlockData() {
      const data = await api.getBlockList({
        type: this.activeBlockType,
        page: 1,
        size: 10
      })
      this.blockList = data.blockList || []
    },
    toMoreBlock(url) {
      this.$router.push(url)
    },
    toHashDetail(hash) {
      this.$router.push(
        `/hashBlock?blockHash=${hash}&type=${this.activeBlockType}`
      )
    },
    isCanJump
  }
}
</script>

<style lang="less" scoped>
.home-data {
  padding-top: 24px;
}
@media screen and (max-width: 1024px) {
  .home-data {
    padding-top: 8px;
  }
}
</style>
